//import imagePreload from "./utils/image-preload";
import scroll from "./utils/scroll";
import alpine from "alpinejs";
import date from "./utils/date";

//imagePreload();
scroll();

function parseEmail(el) {
  return el.innerHTML.replace('<span class="email__at"></span>', "@").replace('<span class="email__dot"></span>', ".");
}

function mailto(email) {
  location.href = "mailto:" + email;
}

function clip(str) {
  navigator.clipboard.writeText(str);
}

window.parseEmail = parseEmail;
window.mailto = mailto;
window.clip = clip;
window.fdate = date;

window.Alpine = alpine;
alpine.start();

