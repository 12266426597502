import Gumshoe from 'gumshoejs/src/js/gumshoe/gumshoe';
import SmoothScroll from 'smooth-scroll/src/js/smooth-scroll/smooth-scroll';

export default function () {
    new Gumshoe('#nav .header__link a', {
        reflow: true,
        events: false,
        navClass: 'header__link--active'
    });

    new SmoothScroll('a[href*="#"]', {
        speed: 800,
        speedAsDuration: true
    });
};
