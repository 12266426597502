import { format } from "date-fns";
import { cs, enGB } from "date-fns/locale";

function getLang(lang) {
  if (lang == "cs") return cs;
  else return enGB;
}

export default function date(stamp, fmt, lang) {
  return format(new Date(stamp), fmt, { locale: getLang(lang) });
}
